// Typescript can't handle the fact that we are neither importing nor exporting any files from this file.
// This empty export is the suggested fix from typescript itself. ¯\_(ツ)_/¯
// typescript error TS1208
export {}

/**
 * TYPESCRIPT DEFINITIONS
 */
interface HTMLDialog extends HTMLDialogElement {
  showModal: () => void;
  close: () => void;
}

interface LiteElementsCache {
  signInDialog?: HTMLDialog;
  signInButton?: HTMLElement;
  signOutButton?: HTMLElement;
  signedInUsernameWrapper?: HTMLElement;
  signedInUsername?: HTMLElement;
  changeStationDialog?: HTMLDialog;
  changeStationButton?: HTMLElement;
  stationOptions?: NodeListOf<HTMLElement>;
  currentPage?: string;
}

/**
 * CACHE FOR DOM ELEMENT REFERENCE
 */
const liteCache: LiteElementsCache = {};
const setupLiteCache = () => {
  // sign in elements
  liteCache.signInDialog = document.querySelector('#sign-in-dialog') as HTMLDialog;
  liteCache.signInButton = document.querySelector('#sign-in-btn') as HTMLElement;
  liteCache.signOutButton = document.querySelector('#sign-out-btn') as HTMLElement;
  liteCache.signedInUsernameWrapper = document.querySelector('#signed-in-username') as HTMLElement;
  liteCache.signedInUsername = document.querySelector('#signed-in-username strong') as HTMLElement;
  // change station elements
  liteCache.changeStationDialog = document.querySelector('#change-station-dialog') as HTMLDialog;
  liteCache.changeStationButton = document.querySelector('#change-station-btn') as HTMLElement;
  liteCache.stationOptions = document.querySelectorAll('.station-option') as NodeListOf<HTMLElement>;
  // shared elements
  liteCache.currentPage = window.location.href;
};

/**
 * EVENT LISTENERS
 */
const addEventListeners = () => {
  liteCache.signInButton.addEventListener('click', () => {
    // show the sign in modal, set a redirect cookie
    liteCache.signInDialog.showModal();
    setCookie('pbsol.redirect_url', liteCache.currentPage);
  });

  liteCache.signOutButton.addEventListener('click', () => {
    fetch('/logout/', {
      method: 'POST',
    }).then(() => {
      localStorage.removeItem('username');
      location.reload();
    });
  });

  liteCache.signInDialog.addEventListener('click', (e) => {
    // this closes the dialog by clicking on the backdrop - there is an inner div with a different id that won't trigger the close
    if ((e.target as HTMLElement).id === 'sign-in-dialog') {
      liteCache.signInDialog.close();
    }
  });

  liteCache.changeStationButton.addEventListener('click', () => {
    liteCache.changeStationDialog.showModal();
    setCookie('pbsol.redirect_url', liteCache.currentPage);
  });

  liteCache.changeStationDialog.addEventListener('click', (e) => {
    if ((e.target as HTMLElement).id === 'change-station-dialog') {
      liteCache.changeStationDialog.close();
    }
  })

  liteCache.stationOptions.forEach((option: HTMLElement) => {
    option.addEventListener('click', () => handleNewStationSelection(option));
  });
};

/**
 * SIGN-IN EVENT HANDLERS
 */
// Adjust DOM elements to display a signed-in user state
const displaySignedInUser = (username: string) => {
  liteCache.signedInUsername.innerText = username;
  liteCache.signedInUsernameWrapper.hidden = false;
  liteCache.signInButton.remove();
  liteCache.signOutButton.hidden = false;
};

// Adjust DOM elements to display a signed-out user state
const displaySignedOutUser = () => {
  liteCache.signInButton.hidden = false;
  liteCache.signedInUsernameWrapper.remove();
  liteCache.signOutButton.remove();
};

// Determine if user is signed in or out and handle consequent side effects
const handlePassiveSignInBehavior = () => {
  // check cookies for user ID
  const pbsUid = getCookieValue('pbs_uid');
  // check localStorage for username
  const savedUsername = localStorage.getItem('username');

  if (pbsUid && savedUsername) {
    // if we have the user ID cookie and localStorage user info, we're all set
    displaySignedInUser(savedUsername);
  } else if (pbsUid) {
    // if we have the user ID but no localStorage username, make the call to /personal/
    const personalFetchBody = JSON.stringify({
      // videoSlug is necessary in order to sign in on a video playback page
      videoSlug:
        window.PBS && window.PBS.playerConfig
          ? window.PBS.playerConfig.slug
          : null,
    });
    fetch('/personal/', {
      method: 'post',
      body: personalFetchBody
    })
    .then((response) => {
      if (!response.ok) {
        throw Error();
      } else {
        return response.json()
      }
    })
    .then((data) => {
      const username = data.username;
      if (username) {
        // caching the name in localStorage so that we can avoid calls in future
        localStorage.setItem('username', username);
        displaySignedInUser(username);
      }
    })
    .catch(() => {
      // If the personal response 404's (which can happen if the user has
      // already logged in on the full site), we shown the sign in button again.
      // We do this because sessions are different between the sites.
      displaySignedOutUser();
    });
  } else {
    // if we have no user ID, user is signed out
    displaySignedOutUser();
  }
}

/**
 * CHANGE STATIONS EVENT HANDLERS
 */
// Handles side effects when a user selects a new station.
const handleNewStationSelection = (selectedStation: HTMLElement) => {
  const stationId = selectedStation.dataset.id;
  const stationName = selectedStation.dataset.shortCommonName;

  // set new station cookies
  setStationCookies(stationId, stationName);

  // either redirect the user or reload the current page
  redirectOrReload();
}

// Manages cookie values after a station is selected.
const setStationCookies = (stationId: string, stationName: string) => {
  // set the station ID
  setCookie('pbsol.station_id', stationId);
  // set the station short common name
  // (riddle me this: all these cookies have the same value for some reason)
  // (only changing all of them for consistency's sake—we don't use most of them)
  setCookie('pbsol.station', stationName);
  setCookie('pbsol.common.name', stationName);
  setCookie('pbsol.common.name.short', stationName);
  setCookie('pbskids.localized', stationName);
}

// Decides where to send the user after a station is selected.
const redirectOrReload = () => {
  if (liteCache.currentPage.includes('/more-stations/')) {
    // if we're on the More Stations page,
    // use the redirect cookie to go back to where we were before selecting a new station
    const redirect = getCookieValue('pbsol.redirect_url');

    if (redirect) {
      window.location.href = redirect;
    } else {
      // if anything went wrong getting the redirect url, default to reloading the page.
      location.reload();
    }
  } else {
    // if we're anywhere other than the More Stations page,
    // that means we're looking at the modal, so we just want to reload the page.
    location.reload();
  }
}

/**
 * SHARED UTILITY FUNCTIONS
 */
// Sets a cookie with the given name and value.
const setCookie = (name: string, value: string) => {
  document.cookie = `${name}=${value}; duration=360; path=/; domain=.pbs.org;`;
}

// Gets the value of a cookie with the given name.
const getCookieValue = (name: string) => {
  const cookieRow = document.cookie.split('; ').find(row => row.startsWith(name));
  // now we split the cookieRow by equal signs to get the name/value as separate entities.
  // BUT, if our cookie value itself has equal signs in it (e.g. it's a redirect URL with
  // query params), we need to rejoin the values of the split-apart array after index 0.
  const cookieValue = cookieRow ? cookieRow.split('=').slice(1).join('=') : null;
  return cookieValue;
}

/**
 * INITIALIZE
 */
setupLiteCache();
addEventListeners();
handlePassiveSignInBehavior();
